import * as anchor from '@project-serum/anchor';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { PhaseCountdown } from './countdown';
import { toDate } from './utils';
import { FairLaunchAccount } from './fair-launch';
import { CandyMachineAccount } from './candy-machine';
import { useWallet } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";


export enum Phase {
  AnticipationPhase, // FL, AKA Phase 0
  SetPrice, // FL, AKA Phase 1
  GracePeriod, // FL, AKA Phase 2
  Lottery, // FL
  RaffleFinished, // FL, AKA Phase 3
  WaitForCM, // FL,
  Phase4,
  Unknown,
}

export function getPhase(
  fairLaunch: FairLaunchAccount | undefined,
  candyMachine: CandyMachineAccount | undefined,
): Phase {
  const curr = new Date().getTime();
  const phaseOne = toDate(fairLaunch?.state.data.phaseOneStart)?.getTime();
  const phaseOneEnd = toDate(fairLaunch?.state.data.phaseOneEnd)?.getTime();
  const phaseTwoEnd = toDate(fairLaunch?.state.data.phaseTwoEnd)?.getTime();
  const candyMachineGoLive = toDate(candyMachine?.state.goLiveDate)?.getTime();
  console.log("Total bots bought: " + candyMachine?.state.itemsRedeemed);
  console.log("Total bots available: " + 1111);
  if (phaseOne && curr < phaseOne) {
    return Phase.AnticipationPhase;
  } else if (phaseOneEnd && curr <= phaseOneEnd) {
    return Phase.SetPrice;
  } else if (phaseTwoEnd && curr <= phaseTwoEnd) {
    return Phase.GracePeriod;
  } else if (
    !fairLaunch?.state.phaseThreeStarted &&
    phaseTwoEnd &&
    curr > phaseTwoEnd
  ) {
    return Phase.Lottery;
  } else if (
    (!fairLaunch || fairLaunch?.state.phaseThreeStarted) &&
    candyMachineGoLive &&
    curr > candyMachineGoLive
  ) {
    return Phase.Phase4;
  } else if (fairLaunch?.state.phaseThreeStarted) {
    if (!candyMachine) {
      return Phase.RaffleFinished;
    } else {
      return Phase.WaitForCM;
    }
  }
  return Phase.Unknown;
}

//const wallet = useWallet();
console.log(anchor.BN);
//console.log("Come back Feb 17");


const Header = (props: {
  phaseName: string;
  total: number | undefined;
  available: number | undefined;
  normalPrice: number | undefined;
  date: anchor.BN | undefined;
  status?: string;
  candyMachine?: CandyMachineAccount | undefined;

}) => {
  const { phaseName, total, available, normalPrice, date, status , candyMachine } = props;
  return (
    <Grid container justifyContent="center">
      <Grid xs={7} justifyContent="center" direction="column">
        <Typography variant="h5" style={{ fontWeight: 600 }}>
            OG Mint Closed
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Secondary mint: Feb. 17
        </Typography>
      </Grid>
      <img  
            src="https://cdn.discordapp.com/attachments/908331814840582225/925161768840478780/DBM_CC_Gif.gif"
            className="mb-6 border-2 rounded border-gray-700"
            alt="DBM Mint"
            style={{
              padding: 24,
              width: "21rem",
            }}
          />
      
      <Grid xs={6} justifyContent="center" direction="column">
        <Typography variant="h5" style={{ fontWeight: 600 }}>
          Drip Bot Mafia
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Dripped-out autonomus mafia
        </Typography>
        <Typography
          variant="h5"
          style={{ fontWeight: 600 }}
          className="text-white"
        >
          {total==0? <small style={{ fontSize: 14 }}>Connect your wallet to see the collection size.</small>
          :<small style={{ fontSize: 14 }}> Total percentage minted: {total==undefined?total:(total==0||available==undefined?0:(available/(total-4444)*100).toFixed(2))} %
          </small>}
        </Typography>
      </Grid>
      <Grid xs={6} container justifyContent="flex-end">
        <PhaseCountdown
          date={toDate(date)}
          style={{ justifyContent: 'flex-end' }}
          status={status || 'COMPLETE'}
        />
        <p 
          style={{ fontWeight: 600,
            background: "#394557",
            fontSize: "20px",
            borderRadius: "10px", 
            padding: 24
          }}>
          Price: {normalPrice==undefined?normalPrice : normalPrice/LAMPORTS_PER_SOL}
        </p>
      </Grid>
    </Grid>
  );
};
//normalPrice==undefined?normalPrice : normalPrice/LAMPORTS_PER_SOL
type PhaseHeaderProps = {
  phase: Phase;
  fairLaunch?: FairLaunchAccount;
  candyMachine?: CandyMachineAccount;
  candyMachinePredatesFairLaunch: boolean;
  rpcUrl: string;
};

export const PhaseHeader = ({
  phase,
  fairLaunch,
  candyMachine,
  candyMachinePredatesFairLaunch,
  rpcUrl,
}: PhaseHeaderProps) => {
  const wallet = useWallet();
  console.log('D', candyMachine);
  console.log('Wallet', wallet);
  
  return (
    <>
      
      {phase === Phase.WaitForCM && (
        <Header
          phaseName={'Phase 3'}
          total={candyMachine?.state.itemsAvailable}
          available={candyMachine?.state.itemsRedeemed}
          date={candyMachine?.state.goLiveDate}
          normalPrice={(candyMachine?.state.price)?.toNumber()}
        />
      )}

      {phase === Phase.Unknown && !candyMachine && (
        <Header
          phaseName={'Loading...'}
          total={0}
          available={0}
          date={undefined}
          normalPrice={0.2*LAMPORTS_PER_SOL}
        />
      )}

      {phase === Phase.Phase4 && (
        <Header
          phaseName={candyMachinePredatesFairLaunch ? 'Phase 3' : 'Phase 4'}
          total={candyMachine?.state.itemsAvailable}
          available={candyMachine?.state.itemsRedeemed}
          date={candyMachine?.state.goLiveDate}
          status="LIVE"
          normalPrice={(candyMachine?.state.price)?.toNumber()}
        />
      )}
    </>
  );
};
